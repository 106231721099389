<template>
  <div class="login-box">
    <div class="login_content">
      <div class="tit_detail">
        <span :class="{left:true, active:showTypeSub}" @click="showTypeSub=true">密码登录</span>
      </div>
      <div class="page_num_one" v-if="showTypeSub">
        <div class="wrapper">
          <img class="img_wrapp" src="https://static-shanghai.hanku.net.cn/agent_system/login/sjh%402x.png" alt="">
          <a-input
            type="text"
            class="input_wrapp"
            placeholder="请输入账号"
            v-model="phone"
            @keydown.enter="nextFous" />
        </div>
        <div class="wrapper">
          <img class="img_wrapp" src="https://static-shanghai.hanku.net.cn/agent_system/login/mm%402x.png" alt="">
          <a-input
          ref="passwrod"
            class="input_wrapp"
            :type="showPass ? 'text' : 'password'"
            placeholder="请输入密码"
            v-model="password"
            @keydown.enter="login_btn" />
          <img class="img_wrapp_pos" @click="showPass=!showPass" src="https://static-shanghai.hanku.net.cn/agent_system/login/xianshikejian%402x.png" alt="">
        </div>
      </div>
      <div class="submit_btn" @click="login_btn">登录</div>
    </div>
  </div>
</template>
<script>
import { Login } from '@/api/login';
export default {
  data() {
    return {
      phone: '', //手机号码
      phoneElse: '', //另一页的手机号吗
      password: '', // 密码
      showPass: false, //密码显示还是不显示
      showTypeSub: true, // 是显示密码登录还是手机登录
      code: '', // 验证码
      codeElse: '', //另一页的验证码
      codeElseOff: '',//隐藏默认密码，解决浏览器默认行为
      checkedPass: false, //记住密码
      getVerCodeTie: '获取短信验证码',
      times: 60,
      base64Img: '',
      getVerCodeTieFlag: true, // 时间没到不能获取
      loginInfo: {}
    }
  },
  methods: {
    /* 回车跳转下个输入框 */
    nextFous() {
      console.log(this.$refs.passwrod.focus());
    },
    /**
     * 登录按钮
     */
    login_btn() {
      if (this.phone === '' || this.password === '') {
        this.$message.error('请输入账号或者密码')
        return 
      } else {
        const data = {
          username: this.phone,
          password: this.password
        }
        Login(data).then(res => {
          if (res.httpStatus == 200) {
            localStorage.setItem('carToken', res.data)
            this.$router.push('/')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
  },
  computed: {
    
  },
  created() {
  }
}
</script>
<style lang="scss">
@function px($px){
  $rem: 144;
  @return ($px/ $rem) + rem;
}
  .ant-input:focus {
    box-shadow: none;
  }
  .el-icon-loading{
    font-size: 40px !important;
  }
  .login-box{
    height: 100%;
    width: 100%;
    background: url(https://static-shanghai.hanku.net.cn/agent_system/bg.png) no-repeat 100% 100%;
    background-size: cover;
    position: relative;
    .logo_left{
      height: 75px;
      width: 330px;
      position: absolute;
      left: 20px;
      top: 30px;
      img{
        height:100%;
        width: 100%;
      }
    }
    .login_content{
      height: 370px;
      width: 400px;
      padding: 35px 30px 0;
      box-sizing: border-box;
      position: absolute;
      left:0;
      top: 0;
      right: 0;
      bottom:0;
      margin: auto;
      background: #fff;
      border-radius:8px;
    }
    .tit_detail{
      display: flex;
      display: -webkit-flex;
      align-items: center;
      margin-bottom: 35px;
      span{
        font-size:18px;
        color:rgba(102,102,102,1);
      }
      span.left{
        margin-right: 10px;
        cursor: pointer;
      }
      span.center{
        width:1px;
        height:20px;
        display: inline-block;
        background: #7B7B7B;
        margin-right: 10px;
      }
      span.right{
        cursor: pointer;
      }
      span.active{
        color: #3D72E7;
      }
    }
    .page_num_one{
      width: 100%;
      .wrapper{
        width:100%;
        height:50px;
        background:#F6F6F6;
        border-radius:4px;
        margin-bottom: 25px;
        padding:14px 15px 14px 18px;
        box-sizing: border-box;
        font-size:14px;
        position: relative;
        .img_wrapp{
          height: 22px;
          width: 21px;
          margin-right: 26px;
        }
        .input_wrapp{
          height: 100%;
          width: 220px;
          background: transparent;
          color: #666;
          border: none;
        }
        .img_wrapp_pos{
          height: 14px;
          width: 20px;
          position: absolute;
          right: 15px;
          top: 18px;
        }
        .input_wrapp150{
          width: 150px;
        }
        .getVerCode{
          display: inline-block;
          width:84px;
          height:17px;
          font-size:12px;
          line-height:17px;
          color:#3D72E7;
          text-align: right;
          position: absolute;
          right: 15px;
          top:18px;
          border:none;
          outline: none;
          background: transparent;
          caret-color: transparent;
          cursor: pointer;
        }
      }
      .wrapper1{
        width: 210px;
        margin-bottom:12px;
        .input_wrapp{
          width: 130px;
        }
        .right_over{
          position: absolute;
          height: 50px;
          width: 120px;
          right:-130px;
          top: 0;
          img{
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .radio_check{
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      padding: 0 12px 0 20px;
      text-align: center;
      .el-checkbox__label{
        font-size: 12px !important;
        color: #1C202C !important;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner {
        border-color:  #3D72E7;
        background: #3D72E7;
      }
      .el-checkbox__input.is-focus .el-checkbox__inner{
        border-color:  #3D72E7;
      }
      .tit_check{
        color: #1265A0;
        font-size: 12px;
        line-height: 20px;
        cursor: pointer;
      }
    }
    .submit_btn{
      width:280px;
      height:50px;
      background:rgba(61,114,231,1);
      box-shadow:0px 11px 16px 0px rgba(36,119,255,0.3);
      border-radius:25px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      line-height: 50px;
      position: absolute;
      bottom: 50px;
      left: 0;
      right:0;
      margin: auto;
      cursor: pointer;
    }
  }
</style>