<template>
  <div class="payagent">
    <div class="payTop" v-if="car === 1">
      <a-button type="danger" @click="delmore">批量删除</a-button>
      <a-button type="primary" @click="priceissue">价格发布</a-button>
    </div>
    <div class="leftselect" v-if="car === 2">
      <a-input-search
        v-model="keywords"
        placeholder="请输入学员ID、姓名、手机号"
        style="width: 280px; margin-right: 20px"
        @search="onSearch"
      />
      <div>
        选择日期
        <a-range-picker :disabled-date="disabledDate" @change="gettime" format="YYYY-MM-DD" />
      </div>
    </div>
    <a-tabs :default-active-key="car" @change="callback">
      <a-tab-pane :key="1" tab="价格列表"> </a-tab-pane>
      <a-tab-pane :key="2" tab="交易记录" force-render> </a-tab-pane>
    </a-tabs>
    <a-table
      :row-selection="
        car === 1
          ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange }
          : null
      "
      :data-source="dataSource"
      :columns="columns"
      @change="pagintion"
      :pagination="{ total:total, current: pageNum, pageSize: pageSize  }"
    >
      <template slot="number" slot-scope="text, record">
        <p>{{ record.id }}</p>
      </template>
      <template slot="disabled" slot-scope="text, record">
        <a-switch
          v-model="record.disabled"
          checked-children="开"
          un-checked-children="关"
          default-checked
          @change="typeChange(record)"
        />
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="link" style="color: red" @click="remove(record)"
          >删除</a-button
        >
        <a-button type="link" @click="editorFomr(record)"
          >编辑</a-button
        >
      </template>
    </a-table>
    <PriceModule :isShow.sync="isShow" :form="formData" :title="title" />
  </div>
</template>

<script>
import PriceModule from "@/components/priceModule";
import {
  pagePayagent,
  delScheme,
  delmoreScheme,
  disabledPryproject,
  truePayproject,
  payProjectList,
} from "@/api/payagent";
export default {
  components: {
    PriceModule,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      selectedRowKeys: [],
      isShow: false,
      formData: {},
      car: 1,
      keywords: "",
      dateFormat: "YYYY/MM/DD",
      total: 0,
      startTime: '',
      endTime: '',
      dataSource: [],
      title: '价格发布',
      columns: [
        {
          title: "序号",
          align: "center",
          dataIndex: "number",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "答题天数",
          align: "center",
          dataIndex: "date",
        },
        {
          title: "价格(元)",
          align: "center",
          dataIndex: "money",
        },
        {
          title: "备注",
          align: "center",
          dataIndex: "remark",
        },
        {
          title: "发布时间",
          align: "center",
          dataIndex: "createdAt",
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "disabled",
          scopedSlots: { customRender: "disabled" },
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.projectInitdata();
  },
  mounted() {},
  methods: {
    // 获取时间
    gettime(e) {
      this.startTime = this.moment(e[0]).format('YYYY/MM')
      this.endTime = this.moment(e[1]).format('YYYY/MM')
    },
    // 交易初始数据
    payInitdata() {
      const data = {
        beginAt: this.startTime, // 开始日期
        endAt: this.endTime, // 结束日期
        name: this.keywords, //用户
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      pagePayagent(data).then(res => {
        this.total = res.data.total
        this.dataSource = res.data.list
        this.dataSource.forEach((item, index) => {
          item.key = item.id;
          
        });
        console.log(this.dataSource);
      })
    },
    // 方案初始数据
    projectInitdata() {
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      payProjectList(data).then((res) => {
        this.total = res.data.total;
        this.dataSource = res.data.list;
        this.dataSource.forEach((item, index) => {
          item.key = item.id;
          item.disabled = !item.disabled
        });
      });
    },
    // 批量删除
    delmore() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请选择要删除的项！");
        return false;
      } else {
        delmoreScheme(this.selectedRowKeys).then(res => {
          this.projectInitdata();
        })
      }
    },
    // 复选框状态值变化事件
    onSelectChange(e) {
      console.log(e);
      this.selectedRowKeys = e;
    },
    priceissue() {
      this.title = '价格发布'
      this.formData = {}
      this.isShow = true;
    },
    remove(e) {
      delScheme(e.key).then((res) => {
        if (res.httpStatus === 200) {
          this.$message.success("删除成功！");
          this.projectInitdata();
        } else {
          this.$message.error(`${res.message}`);
        }
      });
    },
    editorFomr(e) {
      this.formData = JSON.parse(JSON.stringify(e))
      this.title = '编辑价格'
      this.isShow = true
    },
    onSearch() {
      this.pageNum = 1
      this.payInitdata()
    },
    pagintion(e) {
      this.pageNum = e.current
      if (this.car === 1) {
        this.projectInitdata()
      } else {
        this.payInitdata()
      }
    },
    typeChange(e) {
      if (e.disabled === false) {
        disabledPryproject(e.id).then((res) => {
          if (res.httpStatus === 200) {
            this.$message.success("修改状态成");
            this.projectInitdata();
          } else {
            this.$message.error(`${res.message}`);
          }
        });
      } else {
        truePayproject(e.id).then(res => {
          if (res.httpStatus === 200) {
            this.$message.success("修改状态成");
            this.projectInitdata();
          } else {
            this.$message.error(`${res.message}`);
          }
        })
      }
    },
    callback(e) {
      this.car = e;
      this.pageNum = 1
      if (e === 1) {
        this.projectInitdata()
        this.columns = [
          {
            title: "序号",
            align: "center",
            dataIndex: "number",
          },
          {
            title: "答题天数",
            align: "center",
            dataIndex: "date",
          },
          {
            title: "价格(元)",
            align: "center",
            dataIndex: "moeney",
          },
          {
            title: "备注",
            align: "center",
            dataIndex: "remark",
          },
          {
            title: "发布时间",
            align: "center",
            dataIndex: "time",
          },
          {
            title: "状态",
            align: "center",
            dataIndex: "type",
            scopedSlots: { customRender: "type" },
          },
          {
            title: "操作",
            align: "center",
            dataIndex: "operation",
            scopedSlots: { customRender: "operation" },
          },
        ];
      } else {
        this.payInitdata()
        this.columns = [
          {
            title: "用户ID",
            align: "center",
            dataIndex: "id",
          },
          {
            title: "用户昵称",
            align: "center",
            dataIndex: "nickname",
          },
          {
            title: "用户类型",
            align: "center",
            dataIndex: "type",
          },
          {
            title: "交易",
            align: "center",
            dataIndex: "orders",
          },
          {
            title: "交易时间",
            align: "center",
            dataIndex: "payAt",
          },
        ];
      }
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledDate(current) {
      return current && current < this.moment().endOf("day");
    },
    disabledRangeTime(_, type) {
      if (type === "start") {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.payagent {
  width: 100%;
  height: 100%;
  .payTop {
    display: flex;
    justify-content: flex-end;
    ::v-deep .ant-btn {
      margin: 0 10px;
    }
  }
  .leftselect {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  ::v-deep .ant-table-body {
    background: #fff;
    min-height: 680px;
    overflow-y: scroll;
  }
  ::v-deep .ant-table-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 55px;
  }
}
</style>
