import axios from 'axios';

// 获取海报和客服热线
export const getDetail = apiParams => {
  return axios({
    method: 'GET',
    url: 'admin/getConfig',
    params: apiParams
  })
}

// 更改海报
export const putplaybill = apiParams => {
  return axios({
    method: 'PUT',
    url: `admin/updatePoster?posterPath=${apiParams}`
  })
}

// 更改客服热线
export const putserviceHotline = apiParams => {
  return axios ({
    method: 'PUT',
    url: `admin/updateCustomerPhone?customerPhone=${apiParams}`
  })
}