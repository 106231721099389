<template>
  <div class="usercontrol">
    <div class="serachtop">
      <a-input-search v-model="keywords" placeholder="请输入昵称" style="width: 280px;margin-right:20px;" @search="onSearch" />
      <div>
        是否报名： <a-select :default-value="active" style="width: 120px" @change="handleChangeSign">
          <a-select-option :value="1">
            全部
          </a-select-option>
          <a-select-option :value="2">
            已报名
          </a-select-option>
          <a-select-option :value="3">
            未报名
          </a-select-option>
        </a-select>
      </div>
      <div>
        用户类型： <a-select :default-value="type" style="width: 120px" @change="handleChangeIdentity">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option :value="1">
            普通用户
          </a-select-option>
          <a-select-option :value="2">
            驾校教练
          </a-select-option>
        </a-select>
      </div>
    </div>
    <!-- :pagination="{ total: total, pageNum: pageNum, pageSize: pageSize }" -->
    <a-table 
      :data-source="dataSource" 
      :columns="columns"
      :pagination="{ total: total, pageNum: pageNum, pageSize: pageSize  }"
      @change="pagintion">
      <template slot="isSign" slot-scope="text, record">
        <p :style="record.isSign ? 'color:rgb(67, 208, 2);' : 'color:#000;'">{{ record.isSign ? '已报名' : '未报名' }}</p>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="link" @click="entercoach(record)" v-if="record.identity === '普通用户'">教练入驻</a-button>
        <a-button type="link" @click="removecoach(record)" v-else>移除教练</a-button>
        <a-button type="link" @click="detailcoath(record)">详情</a-button>
      </template>
    </a-table>
    <CocthModel v-if="isShow" :isShow.sync="isShow" :item="currentItem" :cars="cars" />
  </div>
</template>

<script>
import { UserList } from '@/api/home';
import { removeCoach } from '@/api/usercontrol';
import CocthModel from '@/components/cocthmodel';
export default {
  name:'',
  components: {
    CocthModel
  },
  data() {
    return {
      currentItem: '',
      cars: 1,
      isShow: false,
      type: '',
      coachName: '',
      active: 1,
      identity: 0,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      keywords: '',
      dataSource: [],
      columns: [
        {
          title: '用户ID',
          align: 'center',
          dataIndex: 'id',
        },
        {
          title: '用户昵称',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '用户类型',
          align: 'center',
          dataIndex: 'identity'
        },
        {
          title: '分享ID',
          align: 'center',
          dataIndex: 'shareId',
        },
        {
          title: '分享人昵称',
          align: 'center',
          dataIndex: 'shareName'
        },
        {
          title: '报名否',
          align: 'center',
          dataIndex: 'isSign',
          scopedSlots: { customRender: 'isSign' }
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
    }
  },
  created() {
    this.initData()
  },
  methods: {
    // 初始数据
    initData() {
      const data = {
        name: this.keywords,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        isSign: this.active
      }
      if(this.identity !== 0) {
        data['identity'] = this.identity
      }
      UserList(data).then(res => {
        console.log(res)
        const arr = res.data.list
        if (arr.length > 0) {
          arr.forEach((item, index) => {
            item.key = index
          });
        }
        this.dataSource = arr
        this.total = res.data.total
      })
    },
    onSearch() {
      this.initData()
    },
    handleChangeSign(e) {
      this.active = e
      this.initData()
    },
    // 用户类型
    handleChangeIdentity(e) {
      this.identity = e
      this.initData()
    },
    pagintion(e) {
      this.pageNum = e.current
      this.initData()
    },
    entercoach(e) {
      this.isShow = true
      this.currentItem = e
      this.cars = 1
    },
    removecoach(e) {
      this.$confirm({
        title: '确定要移除该教练?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          // TODO 接口返回的数据里面没有userId -- 这个接口没有问题
          removeCoach(e.id).then(res => {
            if(res.httpStatus === 200) {
              this.$message.success('删除成功');
              this.initData()
            }
          })
        },
        onCancel: () => {
          console.log('Cancel');
        },
      });
    },
    detailcoath(e) {
      this.cars = 2
      this.isShow = true
      this.currentItem = e
    }
  }
}
</script>

<style scoped lang="scss">
.usercontrol{
  width: 100%;
  height: 100%;
  .serachtop{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  ::v-deep.ant-table-wrapper{
    margin-top: 12px;
  }
  ::v-deep .ant-table-body{
    background: #fff;
    min-height: 750px;
    overflow-y: scroll;
  }
  ::v-deep .ant-table-placeholder{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 55px;
  }
}
</style>
