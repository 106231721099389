import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: resolve => require(['@/layout/index.vue'], resolve),
    reject: '/home',
    children: [
      {
        path: '/',
        name: '首页',
        reject: '/home',
        component: resolve => require(['@/views/Home.vue'], resolve)
      },
      {
        path: '/usercontrol',
        name: '用户管理',
        component: resolve => require(['@/views/usercontrol.vue'], resolve)
      },
      {
        path: '/coachmanagement',
        name: '教练管理',
        component: resolve => require(['@/views/coachmanagement.vue'], resolve)
      },
      {
        path: '/applymengt',
        name: '报名管理',
        component: resolve => require(['@/views/applymengt.vue'], resolve)
      },
      {
        path: '/questionbank',
        name: '题库管理',
        component: resolve => require(['@/views/questionbank.vue'], resolve)
      },
      {
        path: '/issue',
        name: '题目发布',
        component: resolve => require(['@/views/issue.vue'], resolve)
      },
      {
        path: '/picture',
        name: '内容管理',
        component: resolve => require(['@/views/picture.vue'], resolve)
      }, 
      {
        path: '/payagent',
        name: '交易管理',
        component: resolve => require(['@/views/payagent.vue'], resolve)
      }, 
      {
        path: '/secondhandCar',
        name: '二手车管理',
        component: resolve => require(['@/views/secondhandCar.vue'], resolve)
      },
      {
        path: '/bannerChange',
        name: '二手车管理',
        component: resolve => require(['@/views/bannerChange.vue'], resolve)
      }
    ]
  },
  {
    path: '/login',
    name: '登录',
    component: resolve => require(['@/views/login.vue'], resolve),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
