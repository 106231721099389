import axios from 'axios';

// 移除教练
export const removeCoach = params => {
  return axios({
    method: 'PATCH',
    url: `admin/updateNormal?userId=${params}`
  })
}

// 教练入驻
export const enterCoach = params => {
  return axios({
    method: 'put',
    url: 'admin/updateCoach',
    data: params
  })
}
// 用户列表
export const userList = params => {
  return axios({
    method: 'GET',
    url: 'admin/userList',
    data: params
  })
}