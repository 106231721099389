<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <div class="logo">驾考平台</div>
      <a-menu theme="dark" mode="inline" :default-selected-keys="slect">
        <a-menu-item key="1">
          <a-icon type="dashboard" />
          <router-link to="/">首页</router-link>
        </a-menu-item>
        <a-menu-item key="2">
          <a-icon type="user" />
          <router-link to="/usercontrol">用户管理</router-link>
        </a-menu-item>
        <a-menu-item key="3">
          <a-icon type="sound" />
          <router-link to="/coachmanagement">教练管理</router-link>
        </a-menu-item>
        <a-menu-item key="4">
          <a-icon type="audit" />
          <router-link to="/applymengt">报名管理</router-link>
        </a-menu-item>
        <a-menu-item key="5">
          <a-icon type="setting" />
          <router-link to="/questionbank">题库管理</router-link>
        </a-menu-item>
        <a-menu-item key="6">
          <a-icon type="picture" />
          <router-link to="/picture">交易管理</router-link>
        </a-menu-item>
        <a-menu-item key="7">
          <a-icon type="pay-circle" />
          <router-link to="/payagent">内容管理</router-link>
        </a-menu-item>
        <a-menu-item key="8">
          <a-icon type="sound" />
          <router-link to="/secondhandCar">二手车管理</router-link>
        </a-menu-item>
        <a-menu-item key="9">
          <a-icon type="download" />
          <router-link to="/bannerChange">轮播图管理</router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-button type="link" @click="out">
          退出
        </a-button>
      </a-layout-header>
      <a-layout-content>
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
export default {
  data() {
    return {
      collapsed: false,
      slect: ['1']
    };
  },
  created() {
    const path = this.$route.path
    if (path === '/') {
      this.slect = ['1']
    } else if (path === '/coachmanagement') {
      this.slect = ['3']
    } else if (path === '/applymengt') {
      this.slect = ['4']
    } else if (path === '/questionbank' || path === '/issue') {
      this.slect = ['5']
    } else if (path === '/picture') {
      this.slect = ['6']
    } else if (path === '/usercontrol') {
      this.slect = ['2']
    } else if (path === '/payagent') {
      this.slect = ['7']
    } else if (path === '/secondhandCar') {
      this.select = ['8']
    } else if (path === '/bannerChange') {
      this.select = ['9']
    }
  },
  mounted() {
    const path = this.$route.path
    if (path === '/') {
      this.slect = ['1']
    } else if (path === '/coachmanagement') {
      this.slect = ['3']
    } else if (path === '/applymengt') {
      this.slect = ['4']
    } else if (path === '/questionbank' || path === '/issue') {
      this.slect = ['5']
    } else if (path === '/picture') {
      this.slect = ['6']
    } else if (path === '/usercontrol') {
      this.slect = ['2']
    } else if (path === '/payagent') {
      this.slect = ['7']
    } else if (path === 'secondhandCar') {
      this.select = ['8']
    } else if (path === '/bannerChange') {
      this.select = ['9']
    }
  },
  methods: {
    out() {
      localStorage.removeItem('carToken')
      this.$router.push('/login')
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep.ant-layout-sider-children{
  overflow: auto;
}
.ant-layout.ant-layout-has-sider{
  height: 100%;
}
.ant-menu-item > a{
  display: inline;
  opacity: 1;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + a{
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  text-align: center;
  height: 32px;
  margin: 16px;
  font-family: SourceHanSansSC;
  font-weight: 400;
  font-size: 22px;
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  letter-spacing: 0px;
  line-height: 36px;
  text-decoration: none;
}
.ant-layout-content{
  padding: 20px;
}
.ant-layout-header{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>