import axios from 'axios';

/* 查询 */
export const getCarList = apiParams => {
  return axios({
    method: 'get',
    url: '/car/page',
    params: apiParams
  })
}
/* 新增二手车 */
export const addCarDetail = apiParams => {
  return axios({
    method: 'post',
    url: '/car/save',
    data: apiParams
  })
}

/* 更新二手车 */
export const updateCarDetail = apiParams => {
  return axios({
    method: 'post',
    url: '/car/update',
    data: apiParams
  })
}
/* 查询二手车详情 */
export const carDetail = apiParams => {
  return axios({
    method: 'get',
    url: '/car/detail',
    params: apiParams
  })
}

/* 删除二手车 */
export const carDelLine = apiParams => {
  return axios({
    method: 'POST',
    url: `/car/delete/${apiParams}`,
  })
}