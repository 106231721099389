<template>
  <div class="coachmanagement">
    <a-input-search
      v-model="keywords"
      placeholder="请输入用户姓名或手机号"
      style="width: 280px; margin-right: 20px"
      @search="onSearch"
    />
    <div class="coachTwo">
      <a-button :type="car == 1 ? 'primary' : ''" @click="lookdetail(1)"
        >教练列表</a-button
      >
      <a-badge :count="numcoach" dot style="margin-right: 20px">
        <a-button
          :type="car == 2 ? 'primary' : ''"
          @click="lookdetail(2)"
          style="margin: 0"
          >教练申请</a-button
        >
      </a-badge>
      <a-button :type="car == 3 ? 'primary' : ''" @click="lookdetail(3)"
        >审核未通过</a-button
      >
    </div>
    <a-table
      :data-source="dataSource"
      :columns="columns"
      :pagination="{ total:total, pageNum: pageNum, pageSize: pageSize  }"
      @change="pagintion"
    >
      <template slot="operation" slot-scope="text, record">
        <a-button  v-if="car === 1" type="link" @click="extension(record)">推广</a-button>
        <a-button  v-if="car === 1" type="link" @click="moneyChange(record)">佣金修改</a-button>
        <a-button  v-if="car === 1" type="link" @click="removeCoachs(record)">教练移除</a-button>
        <a-button  v-if="car === 1" type="link" @click="detail(record)">详情</a-button>
        <a-button  v-if="car === 2" type="link" @click="checkCocch(record, true)">审核通过</a-button>
        <a-button  v-if="car === 2" type="link" style="color:red;" @click="checkCocch(record, false)">审核不通过</a-button>
      </template>
    </a-table>
    <ExtensionModule v-if="exShow" :exShow.sync="exShow" :item="currentItem" />
    <Moneychange v-if="moneyShow" :moneyShow.sync="moneyShow" :item="currentItem" />
    <CocthModel v-if="isShow" :isShow.sync="isShow" :item="currentItem" :cars="cars" />
  </div>
</template>

<script>
// TODO 教练列表里的除推广之外的三个操作都没有对接
import ExtensionModule from '@/components/extensionModule';
import Moneychange from '@/components/moneychange';
import { coachPages, coachCheck, coachPass, unCoach } from '@/api/coachmanagement'
import { removeCoach } from '@/api/usercontrol';
import CocthModel from '@/components/cocthmodel';
export default {
  name: "",
  components: {
    ExtensionModule,
    Moneychange,
    CocthModel
  },
  props: {},
  data() {
    return {
      cars: 2,
      exShow: false,
      moneyShow: false,
      coachListParams:{ // 教练列表参数
        pageNum: 1,
        pageSize: 10,
        count: true,
      },
      applyForParams: { // 教练申请列表参数
        pageNum: 1,
        pageSize: 10,
        count: true,
      },
      isShow: false,
      currentItem: '',  // 点击推广时当前教练的数据
      keywords: "",
      car: 1,
      numcoach: '',
      dataSource: [],
      columns: [
        {
          title: "用户ID",
          align: "center",
          dataIndex: "userId",
        },
        {
          title: "用户昵称",
          align: "center",
          dataIndex: "name",
        },
        {
          title: "用户姓名",
          align: "center",
          dataIndex: "coachName",
        },
        {
          title: "手机号",
          align: "center",
          dataIndex: "coachPhone",
        },
        {
          title: "驾校名称",
          align: "center",
          dataIndex: "driverName",
        },
        {
          title: "佣金(元)",
          align: "center",
          dataIndex: "commission",
        },
        {
          title: "审核通过时间",
          align: "center",
          dataIndex: "coachAt",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      pageNum:1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {},
  watch: {},
  created() {
    coachCheck(this.applyForParams).then(res => {
      this.numcoach = res.data.total ? res.data.total : 0
    })
    this.getData(1)
  },
  mounted() {},
  methods: {
    // 初始数据
    async getData(flag) {
      let res = null
      this.dataSource = []  // 清空数据
      if(flag === 1) {
        res = await coachPages(this.coachListParams)
      }else if(flag === 2) {
        res = await coachCheck(this.applyForParams)
      }else if(flag === 3) {
        // TODO 审核未通过的接口还有问题-- 相关操作都没做
        res = await unCoach()
      }
      if(res.httpStatus === 200) {
        res.data.list.length > 0 && res.data.list.forEach((item, index) => {
          item['key'] = index
          if(flag === 3) {
            item['status'] = '审核未通过'
          }
        })
        this.dataSource = res.data.list
        this.total = res.data.total
      }else{
        console.log('接口请求错误')
      }
      console.log(this.dataSource)
    },
    onSearch(e) {
      console.log(this.keywords)
      if(this.car === 1) {
        this.coachListParams.name = this.keywords
      }else if (this.car === 2) {
        this.applyForParams.name = this.keywords
      }
      this.getData(this.car)
    },
    lookdetail(e) {
      if(this.car === e) return
      this.car = e;
      console.log(e);
      if (e === 1) {  // 教练列表
        this.columns = [
        {
          title: "用户ID",
          align: "center",
          dataIndex: "userId",
        },
        {
          title: "用户昵称",
          align: "center",
          dataIndex: "name",
        },
        {
          title: "用户姓名",
          align: "center",
          dataIndex: "coachName",
        },
        {
          title: "手机号",
          align: "center",
          dataIndex: "coachPhone",
        },
        {
          title: "驾校名称",
          align: "center",
          dataIndex: "driverName",
        },
        {
          title: "佣金(元)",
          align: "center",
          dataIndex: "commission",
        },
        {
          title: "审核通过时间",
          align: "center",
          dataIndex: "coachAt",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
        ];
      } else if (e === 2) { // 教练申请列表
        this.columns = [
          {
            title: "用户ID",
            align: "center",
            dataIndex: "userId",
          },
          {
            title: "用户昵称",
            align: "center",
            dataIndex: "nickname",
          },
          {
            title: "用户姓名",
            align: "center",
            dataIndex: "name",
          },
          {
            title: "手机号",
            align: "center",
            dataIndex: "phone",
          },
          {
            title: "驾校名称",
            align: "center",
            dataIndex: "driverName",
          },
          {
            title: "操作",
            align: "center",
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' }
          },
        ];
      } else {  // 审核未通过列表
        this.columns = [
          {
            title: "用户ID",
            align: "center",
            dataIndex: "userId",
          },
          {
            title: "用户昵称",
            align: "center",
            dataIndex: "nickname",
          },
          {
            title: "用户姓名",
            align: "center",
            dataIndex: "name",
          },
          {
            title: "手机号",
            align: "center",
            dataIndex: "phone",
          },
          {
            title: "驾校名称",
            align: "center",
            dataIndex: "driverName",
          },
          {
            title: "未通过时间",
            align: "center",
            dataIndex: "uncheckAt",
          },
          {
            title: "状态",
            align: "center",
            dataIndex: "status",
          },
        ];
      }
      this.getData(e)
    },
    pagintion(e) {
      if(this.car === 1) {
        this.coachListParams.pageNum = e.current
      }else if(this.car === 2) {
        this.applyForParams.pageNum = e.current
      }
      this.getData(this.car)
    },
    // 推广
    extension(e) {
      console.log(e);
      this.currentItem = e
      this.exShow = true
    },
    // 佣金修改
    moneyChange(e) {
      console.log(e);
      this.moneyShow = true
      this.currentItem = e
    },
    // 教练移除
    removeCoachs(e) {
      const that = this
      this.$confirm({
        title: '确定移除该教练吗?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          removeCoach(e.userId).then(res => {
            if (res.httpStatus === 200) {
              that.$message.success('移除成功')
              that.getData(1)
            } else {
              that.$message.error(`${res.message}`)
            }
          })
        },
        onCancel() {
        },
      });
    },
    // 详情
    detail(e) {
      this.isShow = true
      this.currentItem = e
      this.cars = 2
    },
    // 教练审核
    checkCocch(e, flag) {
      coachPass({
        applyId: e.id,
        flag
      }).then(res => {
        console.log(res)
        if(res.httpStatus === 200) {
          if(flag) { // 通过审核
            this.$message.success('审批成功')
            coachCheck(this.applyForParams).then(res => {
              this.numcoach = res.data.total ? res.data.total : 0
            })
            this.getData(this.car)
          }else{
            this.$message.success('审批成功')
            coachCheck(this.applyForParams).then(res => {
              this.numcoach = res.data.total ? res.data.total : 0
            })
            this.getData(this.car)
          }
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
.coachmanagement {
  width: 100%;
  height: 100%;
  .coachTwo {
    display: flex;
    align-items: center;
    padding: 10px 0;
    ::v-deep .ant-btn {
      margin-right: 20px;
    }
  }
  ::v-deep .ant-table-body {
    background: #fff;
    min-height: 680px;
    overflow-y: scroll;
  }
  ::v-deep .ant-table-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 55px;
  }
}
</style>
