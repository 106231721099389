<template>
  <div>
    <a-modal
      title="教练推广"
      :visible="exShow"
      @ok="handleOk"
      @cancel="handleCancel"
      width="35%"
    >
    <main>
      <div class="topModule">
        <a-row>
          <a-col :span="3">姓名：</a-col>
          <a-col :span="8">{{item.name}}</a-col>
          <a-col :span="3">手机号:</a-col>
          <a-col :span="8">{{item.coachPhone}}</a-col>
        </a-row>
        <a-row>
          <a-col :span="3">驾校名称：</a-col>
          <a-col :span="12">{{item.driverName}}</a-col>
        </a-row>
        <a-row>
          <a-col :span="3">佣金(元)：</a-col>
          <a-col :span="12">{{item.commission}}元</a-col>
        </a-row>
      </div>
      <h4 style="margin:20px 0">推广列表</h4>
      <!-- :pagination="{ total:params.total, current: params.pageNum, pageSize: params.pageSize  }" -->
      <a-table
        :data-source="dataSource"
        :columns="columns"
        @change="pagintion"
        :pagination="{ total:total, current: params.pageNum, pageSize: pageSize  }"
      >
        <template solt="operation" slot-scope="text, record">
          {{record.operation == 1 ? '普通用户' : '驾校教练'}}
        </template> 
      </a-table>
    </main>
    </a-modal>
  </div>
</template>

<script>
import { coachShare } from '@/api/coachmanagement'
export default {
  props: {
    exShow: {
      type: Boolean
    },
    item: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      params: {
        pageNum: 1,
        userId: '',
        count: true
      },
      columns: [{
        title: 'ID',
        align: 'center',
        dataIndex: 'id'
      }, {
        title: '昵称',
        align: 'center',
        dataIndex: 'nickname'
      }, {
        title: '用户类型',
        align: 'center',
        dataIndex: 'type',
        scopedSlots: { customRender: 'type' }
      }],
      dataSource: [],
      total: 0,
      pageSize: 10
    }
  },
  created() {
    this.params.userId = this.item.userId
    this.getData()
  },
  mounted() {

  },
  methods: {
    // 初始数据
    getData() {
      coachShare(this.params).then(res => {
        if(res.httpStatus === 200) {
          res.data.list.length > 0 && res.data.list.forEach((item,index) => {
            item['key'] = index + 1
          })
          this.dataSource = res.data.list
          this.total = res.data.total
        }
      })
    },
    handleOk() {
      this.$emit('update:exShow', false)
    },
    handleCancel() {
      this.$emit('update:exShow', false)
    },
    pagintion(e) {
      this.params.pageNum = e.current
      this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
main{
  width: 100%;
  height: auto;
  .topModule{
    width: 100%;
    padding: 5px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    ::v-deep .ant-row{
      margin-bottom: 5px;
    }
  }
}
</style>
