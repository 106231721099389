<template>
  <div class="bannerChange">
    <div class="serachtop">
      <a-button type="primary" icon="download" @click="addbanner">新增</a-button>
    </div>
    <a-table
      :data-source="dataSource"
      :columns="columns"
      :rowKey="(record, index) => { return index }"
      bordered
    >
      <span slot="bannerUrl" slot-scope="bannerUrl">
        <img v-if="bannerUrl.indexOf('png') > -1" style="width: 80px;" :src="bannerUrl" @click="handelClick(bannerUrl)">
        <video v-if="bannerUrl.indexOf('mp4') > -1" style="width: 80px;" :src="bannerUrl" />
        <a-modal :visible="previewVisible" :footer="null" @cancel="handelPre">
          <img alt="example" style="width: 100%" :src="imageUrl" />
        </a-modal>
      </span>
      <span slot="action" slot-scope="text, record">
        <div style="display: flex;">
          <a-button type="link" block @click="edita(text, record)">编辑</a-button>
          <a-button type="link" block @click="delLine(text, record)">删除</a-button>
        </div>
      </span>
    </a-table>
    <a-modal v-model="visible" :title="modalTitle" @ok="handleOk">
       
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="连接地址" prop="content">
          <a-input v-model="form.jumpUrl" placeholder="请输入内容" />
        </a-form-model-item>
        <a-form-model-item label="图片">
          <a-upload
            :customRequest="uploadFile"
            list-type="picture-card"
            :show-upload-list="false"
            @preview="handlePreview"
          >
          <img style="width: 80px;" v-if="form.bannerUrl.indexOf('png') > -1" :src="form.bannerUrl" alt="avatar" />
          <video style="height: 100px;" v-if="form.bannerUrl.indexOf('mp4') > -1" controls :src="form.bannerUrl" />
          <div v-if="loading">  
            <a-spin /> <!-- 使用UI库提供的加载动画组件 -->  
          </div> 
          <div v-else>
            <div v-if="!form.bannerUrl">
              <a-icon type="plus" />
                <div class="ant-upload-text">
                  图片上传
                </div>
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { upLoad } from '@/api/home';
import { addBanner, bannerList, editorBanner, delBanner } from '@/api/applymengt' 
export default {
  data() {
    return {
      visible: false,
      dataSource: [],
      previewImage: '',
      columns: [
        {
          title: "跳转地址",
          align: "center",
          key: 'jumpUrl',
          dataIndex: "jumpUrl",
        },
        {
          title: '图片/视频',
          align: 'center',
          key: 'bannerUrl',
          dataIndex: 'bannerUrl',
          scopedSlots: { customRender: 'bannerUrl' },
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          width: '100px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      form: {
        bannerUrl: ''
      },
      rules: {

      },
      labelCol: { span: 4 },
      wrapperCol: { span: 10 },
      modalTitle: '新增',
      previewVisible: false,
      pageSize: 10,
      pageNum: 1,
      imageUrl: '',
      loading: false
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      bannerList({pageSize: this.pageSize, pageNum: this.pageNum}).then(res => {
        if (res.success) {
          res.data.list.forEach(item => {
            item.key = item.id
          });
          this.dataSource = res.data.list
        } else {
          this.$message.error('获取数据失败,请联系管理员或稍后再试!')
        }
      }).catch(err => {
        this.$message.error('获取数据失败,请联系管理员或稍后再试!')
      })
    },
    addbanner() {
      this.visible = true;
      this.modalTitle = '新增'
    },
    edita(e,r) {
      this.visible = true;
      this.modalTitle = '编辑'
      this.form = JSON.parse(JSON.stringify(e))
    },
    delLine(e) {
      delBanner({ids: [e.id]}).then(res => {
        if (res.success) {
          this.$message.success('操作成功')
          this.initData()
        } else {
          this.$message.error('上传失败,请联系管理员或稍后再试!');
        }
      }).catch(err => {
        this.$message.error('上传失败,请联系管理员或稍后再试!');
      })
    },
    handleOk() {
      if (this.modalTitle === '新增') {
        addBanner(this.form).then(res => {
          if (res.success) {
            this.$message.success('操作成功')
            this.initData()
            this.visible = false
          } else {
            this.$message.error('上传失败,请联系管理员或稍后再试!');
          }
        }).catch(err => {
          this.$message.error('上传失败,请联系管理员或稍后再试!');
        })
      } else {
        editorBanner(this.form).then(res => {
          if (res.success) {
            this.$message.success('操作成功')
            this.initData()
            this.visible = false
          } else {
            this.$message.error('上传失败,请联系管理员或稍后再试!');
          }
        }).catch(err => {
          this.$message.error('上传失败,请联系管理员或稍后再试!');
        })
      }
    },
    handelClick(e) {
      this.imageUrl = e
      this.previewVisible = true
    },
    handelPre() {
      this.previewVisible = false
    },
    // 图片多张上传
    uploadFile(e) {
      const formData = new FormData()
      formData.append('file', e.file)
      this.loading = true
      upLoad(formData).then(res => {
        this.loading = false
        this.form.bannerUrl = res.data
      })
    },
    // 图片打开相关
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
     // 图片上传相关
     handleCancel() {
      this.previewVisible = false;
    },
  }
}
</script>

<style scoped lang="scss">
.bannerChange{
  padding: 8px;
  .serachtop{
    padding-bottom: 8px;
  }
}
</style>
