<template>
  <a-modal
    :title="title"
    :visible="isShow"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
    ref="ruleForm"
    :model="form"
    :label-col="labelCol" 
    :wrapper-col="wrapperCol">
      <a-form-model-item label="答题天数">
        <a-input-number v-model="form.date" style="width: 140px;" :min="0" placeholder="请输入天数"  />
      </a-form-model-item>
      <a-form-model-item label="价格(元)">
        <a-input-number v-model="form.money" style="width: 140px;" :min="0" placeholder="请输入价格（元）" />
      </a-form-model-item>
      <a-form-model-item label="会员类型">
        <a-select v-model="form.payType">
          <a-select-option v-for="item in payTypeList" :key="item.value">
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注">
        <a-textarea
          v-model="form.remark"
          placeholder="请输入备注"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-model-item>
      <a-form-model-item label="状态">
        <a-switch v-model="form.disabled" @change="onChange" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { addPayproject, payEditor } from '@/api/payagent'
export default {
  props: {
    isShow: {
      type: Boolean
    },
    form: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      payTypeList: [{value: 1, text: '普通会员(模拟考试、顺序练习)'}, { value: 2, text: '专项会员(专项练习、视频讲解、真实考场模拟)' }, {value: 3, text: '超级会员(全部都可以正常使用)'}]
    }
  },
  methods: {
    handleOk() {
      if (this.title === '价格发布') {
        const data = {...this.form, disabled: !this.form.disabled}
        addPayproject(data).then(res => {
          if (res.httpStatus === 200) {
            this.$message.success('发布成功');
          }
          this.$parent.projectInitdata()
          this.$emit('update:isShow', false)
        })
      } else {
        payEditor({...this.form, disabled: !this.form.disabled}).then(res => {
          if (res.httpStatus === 200) {
            this.$message.success('更新成功');
          }
          this.$parent.projectInitdata()
          this.$emit('update:isShow', false)
        })
      }
    },
    handleCancel() {
      this.$emit('update:isShow', false)
    },
    onChange() {
      
    }
  }
}
</script>

<style scoped lang="scss">
</style>
