import axios from 'axios';

// 查询用户列表
export const UserList = apiParams => {
  return axios({
    method: 'get',
    url: 'admin/userList',
    params: apiParams
  })
}

// 文件上传
export const upLoad = apiParams => {
  return axios({
    method: 'post',
    url: 'file/upload',
    data: apiParams
  })
}

// 首页 数据统计
export const statisticsData = params => {
  return axios({
    method: 'get',
    url: 'admin/data',
    params
  })
}