import axios from 'axios';
import { message } from 'ant-design-vue'
import router from '../router'


// axios.defaults.baseURL = "https://jiaxiao.lcpany.com/jiaxiao"
axios.defaults.baseURL = "https://duojietai.com/jiaxiao"
// axios.defaults.baseURL = "http://47.98.224.211:9001/test/"
axios.defaults.headers["Content-Type"] = "application/json;charset=UTF-8"
axios.defaults.cache = false

// 请求拦截
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('carToken')) {
      const tokenId = localStorage.getItem('carToken') || ''
      config.headers['Authorization'] = tokenId
    }
    return config
  },
  error => {
    return Promise.reject(error)
  } 
)

// 对错误做出响应
axios.interceptors.response.use(
  response => {
    if (response.data.httpStatus === 401) {
      localStorage.removeItem("carToken")
      router.push('/login')
      message.error(response.data.message)
    } else {
      return Promise.resolve(response.data)
    }
  },
  error => {
    // 对响应错误做点什么
    if (error.response.data.httpStatus === 401) {
      localStorage.removeItem("carToken")
    } else if (error.response.data.httpStatus === 462) {
      message.error('权限不足');
    } else {
      message.error('账号密码错误,请重试！')
    }
    return Promise.reject(error)
  }
)