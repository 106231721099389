import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/css/main.scss';
import moment from 'moment';
import './plugins/axios';
import echarts from 'echarts'
Vue.use(Antd);
Vue.prototype.moment = moment;
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  const isLogin = localStorage.getItem('carToken') // 是否登录
  if (to.path !== '/login' && !isLogin) {
    next({
      path: '/login'
    })
  } else {
    next()
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
