import axios from 'axios';

// 题目列表
export const topicLists = apiParams => {
  return axios({
    method: 'get',
    url: 'admin/topicList',
    params: apiParams
  })
}

// 启用题目
export const enalb = apiParams => {
  return axios({
    method: 'PATCH',
    url: `admin/enable/${apiParams}`
  })
}

// 禁用题目
export const dsiablepic = apiParams => {
  return axios({
    method: 'PATCH',
    url: `admin/disable/${apiParams}`
  })
}

// 删除单个题目
export const delTopic = apiParams => {
  return axios({
    method: 'DELETE',
    url: `admin/delete/${apiParams}`
  })
}

// 批量删除题目
export const delmorePic = apiParams => {
  return axios({
    method: 'DELETE',
    url: 'admin/batchDelete',
    data: apiParams
  })
}

// 编辑题目
export const putPic = apiParams => {
  return axios({
    method: 'PUT',
    url: 'admin/update',
    data: apiParams
  })
}

// 新增题目
export const addPic = apiParams => {
  return axios({
    method: 'post',
    url: 'admin/addTopic',
    data: apiParams
  })
}

// 题目详情
export const picDetail = apiParams => {
  return axios({
    method: 'get',
    url: `admin/getTopic/${apiParams}`
  })
}