import axios from 'axios';

// 教练列表
export const coachPages = params => {
  return axios({
    method: 'get',
    url: 'admin/coachList',
    params
  })
}

// 教练申请列表
export const coachCheck = params => {
  return axios({
    method: 'GET',
    url: 'admin/coachCheckList',
    params
  })
}

// 教练审核通过/不通过
export const coachPass = params => {
  return axios({
    method: 'PATCH',
    url: `admin/checkCoach`,
    data: params
  })
}

// 教练审核未通过列表
export const unCoach = params => {
  return axios({
    method: 'get',
    url: 'admin/unCheckList',
    params
  })
}

// 教练推广列表
export const coachShare = params => {
  return axios({
    method: 'get',
    url: 'admin/shareList',
    params
  })
}

// 佣金修改
export const moneyChange = params => {
  return axios({
    method: 'put',
    url: 'admin/updateCommission',
    data: params
  })
}