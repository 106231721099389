<template>
  <div class="picture">
    <div class="picrueTop fl-between">
      <a-button :type="car == 1 ? 'primary' : ''" @click="() => { car = 1 }">海报上传</a-button>
      <a-button :type="car == 2 ? 'primary' : ''" @click="() => { car = 2 }">客户热线</a-button>
    </div>
    <div class="playbill" v-if="car == 1">
      <div class="leftimg">
        <img class="playbillimg" :src="imgplay" alt="" srcset="">
      </div>
      <a-upload
        name="avatar"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        :customRequest="uploadFile"
      >
        <div>
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">
            海报上传
          </div>
        </div>
      </a-upload>
    </div>
    <div class="hotline" v-else>
      <a-row type="flex">
        <a-col :span="3" style="text-align: right;line-height: 29px;padding-right:10px;">
          客服热线:
        </a-col>
        <a-col :span="8">
          <a-input-search placeholder="请输入客服热线" enter-button="保存" size="large" @search="onSearch" v-model="hotline" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { upLoad } from '@/api/home';
import { getDetail, putserviceHotline, putplaybill } from '@/api/picture';
export default {
  data() {
    return {
      car: 1,
      loading: false,
      imgplay: '',
      hotline: ''
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      getDetail().then(res => {
        this.imgplay = res.data.showPoster
        this.hotline = res.data.customerPhone
      })
    },
    uploadFile(e) {
      const formData = new FormData()
      formData.append('file', e.file)
      upLoad(formData).then(res => {
        putplaybill(res.data).then(res => {
          this.imgplay = res.data
          if (res.httpStatus === 200) {
            this.$message.success('更新成')
            this.initData()
          } else {
            this.$message.error(`${res.message}`)
          }
        })
      })
    },
    // 保存
    onSearch(e) {
      putserviceHotline(this.hotline).then(res => {
        if (res.httpStatus === 200) {
          this.$message.success('保存成功')
          this.initData()
        } else {
          this.$message.error(`${res.message}`)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.picture{
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  overflow-y: scroll;
  .picrueTop{
    width: 240px;
    padding: 20px;
  }
  .playbill{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .leftimg{
      width: 426px;
      height: 677px;
      position: relative;
      border: 1px dashed #eee;
      .playbillimg{
        width: 100%;
        height: 100%;
      }
      .ewm{
        width: 45px;
        height: 45px;
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
    ::v-deep.ant-upload-picture-card-wrapper{
      width: auto;
      display: block;
    }
  }
}
</style>
