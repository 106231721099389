<template>
  <a-modal
    title="佣金修改"
    :visible="moneyShow"
    @ok="handleOk"
    @cancel="handleCancel"
  >
  <main>
    <a-row>
      <a-col :span="4">姓名：</a-col>
      <a-col :span="8">{{item.name}}</a-col>
      <a-col :span="4">手机号:</a-col>
      <a-col :span="8">{{item.phone}}</a-col>
    </a-row>
    <a-row>
      <a-col :span="4">驾校名称：</a-col>
      <a-col :span="12">{{item.driverName}}</a-col>
    </a-row>
    <a-row>
      <a-col :span="4">佣金(元)：</a-col>
      <!-- <a-col :span="12">{{item.commission}}元</a-col> -->
      <a-col :span="12"><a-input
        v-model="item.commission"
      /></a-col>
    </a-row>
  </main>
  </a-modal>
</template>

<script>
import { moneyChange } from '@/api/coachmanagement'
export default {
  name:'',
  components: {

  },
  props: {
    moneyShow: {
      type: Boolean
    },
    item: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleOk() {
      moneyChange({ commission: this.item.commission, userId: this.item.userId }).then(res => {
        if (res.httpStatus === 200) {
          this.$message.success('修改成功')
          this.$parent.getData(1)
          this.$emit('update:moneyShow', false)
        } else {
          this.$message.error(`${res.message}`)
        }
      })
    },
    handleCancel() {
      this.$emit('update:moneyShow', false)
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .ant-input{
  height: 23px;
}
::v-deep .ant-btn{
  height: 23px;
}
</style>
