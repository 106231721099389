<template>
  <div class="issue">
    <div class="issueTop fl-between">
      <p>请填写考题相关信息</p>
    </div>
    <div class="issuemain">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="科目(可多选)" prop="subject">
          <a-radio-group v-model="form.subject">
            <a-radio :value="0">
              科目一和科目四
            </a-radio>
            <a-radio :value="1">
              科目一
            </a-radio>
            <a-radio :value="2">
              科目四
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="类型(单选)" prop="type">
          <a-radio-group v-model="form.type" @change="radioChange">
            <a-radio :value="1">
              判断题
            </a-radio>
            <a-radio :value="2">
              单选题
            </a-radio>
            <a-radio :value="3">
              多选题
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- 图片上传搞定 -->
        <a-form-model-item label="图片">
          <a-upload
            :customRequest="uploadFile"
            list-type="picture-card"
            :file-list="form.imgList"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="form.imgList.length < 4">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                图片上传
              </div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="disabled">
          <a-switch checked-children="开" un-checked-children="关" v-model="form.disabled" />
        </a-form-model-item>
        <a-form-model-item label="考题内容" prop="content">
          <a-textarea placeholder="请输入考题内容" :auto-size="{ minRows: 2, maxRows: 6 }" v-model="form.content" />
        </a-form-model-item>
        <a-form-model-item label="语言类型" prop="lanType">
          <a-select v-model="form.lanType">
            <a-select-option v-for="item in lanTypeList" :key="item.label">
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="专项联系类型" prop="specialType">
          <a-select v-model="form.specialType" @change="specialTypeChange">
            <a-select-option v-for="item in specialList" :key="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="视频讲解类型" prop="videoType">
          <a-select v-model="form.videoType" @change="handleVideoChange">
            <a-select-option v-for="item in videoTypeList" :key="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 语音导读 -->
        <a-form-model-item label="语音导读">
          <a-upload
            :customRequest="uploadvoice"
            :file-list="form.audios"
            @change="changeVoice"
          >
            <a-button> <a-icon type="upload" /> 语音上传 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item v-if="form.type == 1" label="参考答案" prop="answer">
          <a-radio-group v-model="form.answer">
            <a-radio value="A">
              正确
            </a-radio>
            <a-radio value="B">
              错误
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- 单选题 -->
        <a-form-model-item v-if="form.type == 2" label="选项与答案" prop="answerad">
          <div class="fl-between" style="width: 150px;">
            <a-button :type="car === 1 ? 'primary' : ''" @click="changeType(1)">文字</a-button>
            <a-button :type="car === 2 ? 'primary' : ''" @click="changeType(2)">图片</a-button>
          </div>
          <div v-if="car === 1">
            <a-radio-group v-model="form.three">
              <a-radio value="A">A</a-radio>
              <a-radio value="B">B</a-radio>
              <a-radio value="C">C</a-radio>
              <a-radio value="D">D</a-radio>
            </a-radio-group>
            <div class="chosebox" v-for="(item, index) in form.optionCreates" :key="index">
              {{String.fromCharCode((65 + index))}}<a-input placeholder="请输入内容" v-model="item.content" style="{ width: 60, marginLeft: 30 }" />
            </div>
          </div>
          <div v-if="car === 2">
            <a-radio-group class="cloumnRadio" v-model="form.three">
              <a-radio value="A">A</a-radio>
              <a-radio value="B">B</a-radio>
              <a-radio value="C">C</a-radio>
              <a-radio value="D">D</a-radio>
            </a-radio-group>
            <div class="chosebox" v-for="(item, index) in form.optionCreates" :key="index">
              {{String.fromCharCode((65 + index))}}
              <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :customRequest="(params) => Choices(params, index)"
            >
              <img v-if="item.content" :src="item.content" alt="avatar" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            </div>
          </div>
        </a-form-model-item>
        <!-- 多选题 -->
        <a-form-model-item v-if="form.type == 3" label="选项与答案" prop="answermore">
          <div class="fl-between" style="width: 150px;">
            <a-button :type="car === 1 ? 'primary' : ''" @click="changeType(1)">文字</a-button>
            <a-button :type="car === 2 ? 'primary' : ''" @click="changeType(2)">图片</a-button>
          </div>
          <div v-if="car == 1">
            <a-checkbox-group class="checkwarp" v-model="form.checkvalue">
              <a-checkbox value="A">A</a-checkbox>
              <a-checkbox value="B">B</a-checkbox>
              <a-checkbox value="C">C</a-checkbox>
              <a-checkbox value="D">D</a-checkbox>
            </a-checkbox-group>
            <div class="chosebox" v-for="(item, index) in form.optionCreates" :key="index">
              {{String.fromCharCode((65 + index))}}<a-input placeholder="请输入内容" v-model="item.content" :style="{ width: 100, marginLeft: 30 }" />
            </div>
          </div>
          <div v-if="car == 2">
            <a-checkbox-group class="checkwarp" v-model="form.checkvalue">
              <a-checkbox value="A">A</a-checkbox>
              <a-checkbox value="B">B</a-checkbox>
              <a-checkbox value="C">C</a-checkbox>
              <a-checkbox value="D">D</a-checkbox>
            </a-checkbox-group>
            <div class="chosebox" v-for="(item, index) in form.optionCreates" :key="index">
              {{String.fromCharCode((65 + index))}}
              <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :customRequest="(params) => Choices(params, index)"
              >
                <img v-if="item.content" :src="item.content" alt="avatar" />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">
                    上传图片
                  </div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="视频解析">
          <a-upload
            :customRequest="uploadvideo"
            :multiple="true"
            :file-list="form.videos"
            @change="changevideo"
          >
            <a-button> <a-icon type="upload" /> 视频上传 </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="issueBottom fl-end">
      <a-button type="primary" html-type="submit" @click="handleSubmit">立即添加</a-button>
      <a-button style="margin-left: 20px;" @click="goback">返回</a-button>
    </div>
  </div>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
import { upLoad } from '@/api/home';
import { addPic, picDetail, putPic } from '@/api/questionbank';
export default {
  data() {
    return {
      echoCar: '',
      echoType: '',
      echoList: [],
      loading: false,
      car: 1,
      labelCol: { span: 4 },
      wrapperCol: { span: 10 },
      form: {imgList: [], audios: [], videos: [], disabled: true, optionCreates: [{
        optionNo: 'A',
        content: '',
        isTrue: false,
      }, {
        optionNo: 'B',
        content: '',
        isTrue: false,
      }, {
        optionNo: 'C',
        content: '',
        isTrue: false,
      }, {
        optionNo: 'D',
        content: '',
        isTrue: false,
      }]},
      plainOptions: ['科一', '科四'],
      lanTypeList: [{ value: '安多语', label: 1 }, { value: '甘肃方言', label: 2 }, { value: '卫藏语', label: 3 }, { value: '中文简体', label: 4 }],
      fileList: [],
      voiceList: [],
      previewVisible: false,
      previewImage: '',
      rules: {
        img: [{ required: true, message: '请至少上传一张图片', trigger: 'change' }],
        type: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
        content: [{ required: true, message: '请输入考题内容(安多语)', trigger: 'bulr' }],
        contentLan2: [{ required: true, message: '请输入考题内容(甘肃语)', trigger: 'bulr' }],
        contentLan3: [{ required: true, message: '请输入考题内容(卫藏语)', trigger: 'bulr' }],
        voice: [{ required: true, message: '请上传导读语音', trigger: 'change' }],
        answer: [{ required: true, message: '请选择答案', trigger: 'change' }],
        video: [{ required: true, message: '请上传视频解析', trigger: 'change' }]
      },
      // 多选文字结果
      checkvalue: [],
      // 判断题
      judge: [{
        content: '正确',
        isTrue: false,
        optionNo: 'A',
        type: 1
      }, {
        content: '错误',
        isTrue: false,
        optionNo: 'B',
        type: 1
      }],
      /* z专项联系类型 */
      specialList: [1, 2, 3, 4, 5],
      videoTypeList: [1, 2,3,4,5]
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    const id = parseInt(this.$route.query.id)
    if (id) {
      picDetail(id).then(res => {
        if (!res.data.imgList || res.data.imgList.length === 0) {
          res.data.imgList = []
        } else if (res.data.imgList[0].indexOf('uid') !== -1) {
          res.data.imgList.forEach((item, index) => {
            res.data.imgList[index] = JSON.parse(res.data.imgList[index])
          });
        } else {
          let arrs = []
          arrs = JSON.parse(JSON.stringify(res.data.imgList))
          res.data.imgList.forEach((item, index) => {
            res.data.imgList[index] = {url: '', "uid": '', name: ''}
            res.data.imgList[index].url = arrs[index]
            res.data.imgList[index].uid = `${index - 1}`
            res.data.imgList[index].name = `${index}.png`
          });
        }
        if (!res.data.videos || res.data.videos.length === 0) {
          res.data.videos = []
        } else if (res.data.videos[0].indexOf('uid') !== -1) {
          res.data.videos.forEach((item, index) => {
            res.data.videos[index] = JSON.parse(res.data.videos[index])
          });
        } else {
          let arrs = []
          arrs = JSON.parse(JSON.stringify(res.data.videos))
          res.data.videos.forEach((item, index) => {
            res.data.videos[index] = {url: '', "uid": '', name: ''}
            res.data.videos[index].url = arrs[index]
            res.data.videos[index].uid = `${index - 1}`
            res.data.videos[index].name = `${index}.mp4`
          });
        }
        if (!res.data.audios || res.data.audios.length === 0) {
          res.data.audios = []
        } else if (res.data.audios[0].indexOf('uid') !== -1) {
          res.data.audios.forEach((item, index) => {
            res.data.audios[index] = JSON.parse(res.data.audios[index])
          });
        } else {
          let arrs = []
          arrs = JSON.parse(JSON.stringify(res.data.audios))
          res.data.audios.forEach((item, index) => {
            res.data.audios[index] = {url: '', "uid": '', name: ''}
            res.data.audios[index].url = arrs[index]
            res.data.audios[index].uid = `${index - 1}`
            res.data.audios[index].name = `${index}.mp3`
          });
        }
        this.form = res.data
        this.form.checkvalue = JSON.parse(res.data.checkvalue)
        this.form.content = res.data.contentStr
        this.form.disabled = !this.form.disabled
        this.echoType = res.data.type
        this.echoList = res.data.optionCreates
        this.echoCar = res.data.optionCreates[0].type
        this.car = res.data.optionCreates[0].type
      })
    } else {
    }
  },
  mounted() {

  },
  methods: {
    // 单选题切换
    radioChange(e) {
      const type = e.target.value
      if (this.form.id) {
        if (type === this.echoType) {
          this.form.optionCreates = this.echoList
        } else if (type === 1) {
          this.form.optionCreates = this.judge
        } else if (type === 2 || type === 3) {
          this.form.optionCreates = [{
            optionNo: 'A',
            content: '',
            isTrue: false,
          }, {
            optionNo: 'B',
            content: '',
            isTrue: false,
          }, {
            optionNo: 'C',
            content: '',
            isTrue: false,
          }, {
            optionNo: 'D',
            content: '',
            isTrue: false,
          }]
        }
      }
    },
    // 文字图片相互切换
    changeType(e) {
      this.car = e
      if (!this.form.id) {
        (this.form.optionCreates).forEach(item => {
          item.content = ''
        });
      } else {
        if (this.form.type === this.echoType && e === this.echoCar) {
          this.form.optionCreates = this.echoList
        } else {
          this.form.optionCreates = [{
            optionNo: 'A',
            content: '',
            isTrue: false,
          }, {
            optionNo: 'B',
            content: '',
            isTrue: false,
          }, {
            optionNo: 'C',
            content: '',
            isTrue: false,
          }, {
            optionNo: 'D',
            content: '',
            isTrue: false,
          }]
        }
      }
    },
    // 单选上传图片
    Choices(e, i) {
      const formData = new FormData()
      formData.append('file', e.file)
      upLoad(formData).then(res => {
        this.form.optionCreates[i].content = res.data
      })
    },
    // 立即添加
    handleSubmit() {
      if (this.form.type === 1) {
        // 判断题
        this.judge.forEach(item => {
          if (item.optionNo === this.form.answer) {
            item.isTrue = true
          }
        });
        this.form.optionCreates = this.judge
      } else if (this.form.type === 2) {
        // 单选题
        this.form.optionCreates.forEach(item => {
          if (this.car === 1) {
            item.type = 1
          } else {
            item.type = 2
          }
          if (item.optionNo === this.form.three) {
            item.isTrue = true
          } else {
            item.isTrue = false
          }
        });
      } else {
        // 多选题
        const arr = this.form.checkvalue
        this.form.optionCreates.forEach((item, index) => {
          if (this.car === 1) {
            item.type = 1
          } else {
            item.type = 2
          }
        if (arr.includes(this.form.optionCreates[index].optionNo)) {
          this.form.optionCreates[index].isTrue = true
        }
        });
      }
      // 简单校验
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // 新增
          let auarr = []
          let voarr = []
          auarr[0] = JSON.stringify(this.form.audios[0]) || ''
          voarr[0] = JSON.stringify(this.form.videos[0]) || ''
          if (!this.form.id) {
            addPic({...this.form, 
                    disabled: !this.form.disabled,
                    imgList: JSON.stringify(this.form.imgList), 
                    checkvalue: JSON.stringify(this.form.checkvalue), 
                    audios: auarr,
                    videos: voarr}).then(res => {
              if (res.httpStatus === 200) {
                this.$message.success('新增题目成功')
                this.$router.back(-1)
              } else {
                this.$message.error(`${res.message}`)
              }
            })
          } else { // 编辑
            putPic({...this.form, disabled: !this.form.disabled, imgList: JSON.stringify(this.form.imgList), checkvalue: JSON.stringify(this.form.checkvalue), audios: auarr, videos: voarr}).then(res => {
              if (res.httpStatus === 200) {
                this.$message.success('修改题目成功')
                this.$router.back(-1)
              } else {
                this.$message.error(`${res.message}`)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    // 返回
    goback() {
      this.$router.back(-1)
      this.$refs.ruleForm.resetFields();
    },
    // 图片上传相关
    handleCancel() {
      this.previewVisible = false;
    },
    // 图片多张上传
    uploadFile(e) {
      const formData = new FormData()
      formData.append('file', e.file)
      upLoad(formData).then(res => {
        this.form.imgList.push({
          uid: e.file.uid,
          name: e.file.name,
          url: res.data
        })
      })
    },
    // 语音上传
    uploadvoice(e) {
      const formData = new FormData()
      formData.append('file', e.file)
      upLoad(formData).then(res => {
        this.form.audios.push({
          uid: 1,
          name: e.file.name,
          url: res.data
        })
        // 自定义上传成功
        e.onSuccess(res, e.file)
      })
    },
    // 视频上传
    uploadvideo(e) {
      const formData = new FormData()
      formData.append('file', e.file)
      upLoad(formData).then(res => {
        this.form.videos.push({
          uid: 1,
          name: e.file.name,
          url: res.data
        })
        e.onSuccess(res, e.file)
      })
    },
    // 语音相关
    changeVoice(info) {
      let fileList = [...info.fileList] || [];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.form.audios = fileList;
    },
    // 视频相关
    changevideo(info) {
      let fileList = [...info.fileList] || [];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.form.videos = fileList;
    },
    // 图片打开相关
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 图片上传相关
    handleChange({ fileList }) {
      (this.form.imgList).forEach((item, index) => {
        if (this.form.imgList[index].status === "removed") {
          this.form.imgList.splice(index, 1)
        }
      });
    },
    specialTypeChange(e) {

    },
    handleVideoChange(e) {

    }
  }
}
</script>

<style scoped lang="scss">
.issue{
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .issueTop{
    padding: 20px;
  }
  .issuemain{
    flex: 1;
    overflow-y: scroll;
    &::-webkit-scrollbar {
    width:0 !important;
    }
    padding: 0 20px;
    .chosebox{
      display: flex;
      align-items: center;
      ::v-deep .ant-input{
        margin-left: 10px;
      }
    }
    ::v-deep.ant-checkbox-wrapper + .ant-checkbox-wrapper{
      margin: 0;
    }
    .avatar-uploader{
      img{
        width: 70px;
        height: 70px;
      }
    }
  }
  .issueBottom{
    padding: 20px;
  }
}
</style>
