export const download = (file) => {
  const url = window.URL.createObjectURL(new Blob([file],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'}))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', '下载文件名称.xls') // 下载文件的名称及文件类型后缀
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link); // 下载完成移除元素 
  window.URL.revokeObjectURL(url); // 释放掉blob对象
}

