<template>
  <div class="home">
    <div class="homeTop">
      <div class="slelcTop">
        <p class="title">支付方案</p>
        <a-date-picker
          mode="year"
          placeholder="请选择年份"
          format="YYYY"
          v-model="year"
          :open="yearShowOne1"
          @openChange="openChangeOne1"
          @panelChange="panelChangeOne1"
        />
        <a-range-picker
          :placeholder="['开始月份', '结束月份']"
          format="MM"
          :value="value"
          :mode="mode2"
          @panelChange="handlePanelChange2"
          @change="handleChange"
        />
      </div>
      <div class="numberBottom">
        <div 
          class="box" 
          v-for="(item, index) in boxList" 
          :key="index"
          :style="index + 1 % 3 === 1 ? `background-image:url(${img1})` : index + 1 % 3 === 2 ? `background-image:url(${img2})` : `background-image:url(${img3})` ">
          <div class="content">
            <div class="contentTop">
              <span>练习天数：{{ item.dayNum }}</span
              ><span>价格： {{ item.price }}</span>
            </div>
            <div class="contentBottom">
              <a-statistic
                title="总销售数量（个）"
                :value="item.sellNum"
                style="color: #fff;"
              />
              <a-statistic
                title="总收益（元）"
                :value="item.total"
                style="color: #fff"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chartsbom">
      <div class="slelcTop">
        <p class="title">月度实际收益≈(单位：万元）</p>
        <a-date-picker
          mode="year"
          placeholder="请选择年份"
          format="YYYY"
          v-model="year"
          :open="yearShowOne"
          @openChange="openChangeOne"
          @panelChange="panelChangeOne"
        />
      </div>
      <div
        id="mation_top_fifth_left_echarts"
        ref="mation_top_fifth_left_echarts"
      ></div>
    </div>
  </div>
</template>

<script>
import { statisticsData } from "@/api/home.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      img1: require("../assets/image/home1.png"),
      img2: require("../assets/image/home2.png"),
      img3: require("../assets/image/home3.png"),
      date: "",
      mode2: ["month", "month"],
      value: [],
      params: {
        year: "",
        beginDate: "",
        endDate: "",
      },
      XData: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      valueData: [],
      boxList: [],
      year: null,
      yearShowOne: false,
      yearShowOne1: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.year = this.moment(new Date(), 'YYYY')
    this.params.year = this.moment(new Date()).format('YYYY')
    this.getData()
  },
  mounted() {
    this.panelChangeOne(this.moment(new Date()).format('YYYY'))
    this.drawChart1();
  },
  methods: {
    // 初始化数据
    getData() {
      statisticsData(this.params).then((res) => {
        this.boxList = res.data.payProjectDataList
        this.XData = res.data.monthDataList
        this.valueData = res.data.moneyList
      });
    },
    // 
    handlePanelChange2(value, mode) {
      this.value = value;
      this.mode2 = [
        mode[0] === "date" ? "month" : mode[0],
        mode[1] === "date" ? "month" : mode[1],
      ];
      this.params.beginDate = this.moment(value[0]._d).format("YYYY-MM");
      this.params.endDate = this.moment(value[1]._d).format("YYYY-MM");
      this.params.year = this.params.beginDate.split("-")[0];
      this.getData();
    },
    // 
    handleChange(value) {
      this.value = value;
    },
    // 
    openChangeOne(status) {
      //status是打开或关闭的状态
      if (status) {
        this.yearShowOne = true;
      }
    },
    openChangeOne1(status) {
      //status是打开或关闭的状态
      if (status) {
        this.yearShowOne1 = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.year = this.moment(value._d)
        .format("YYYY-DD-MM")
        .split("-")[0];
      this.yearShowOne = false;
      statisticsData({ year: this.year }).then((res) => {
        console.log(res);
        this.XData = res.data.monthDataList
        this.valueData = res.data.moneyList
        this.drawChart1()
      });
    },
    panelChangeOne1(value) {
      this.year = this.moment(value._d)
        .format("YYYY-DD-MM")
        .split("-")[0];
      this.yearShowOne1 = false;
      statisticsData({ year: this.year }).then((res) => {
        console.log(res);
        this.XData = res.data.monthDataList
        this.valueData = res.data.moneyList
        this.drawChart1()
      });
    },
    // 第一个柱状图
    drawChart1() {
      // 基于准备好的dom，初始化echarts实例
      const myChart1 = this.$echarts.init(
        this.$refs.mation_top_fifth_left_echarts
      );
      // 指定图表的配置项和数据
      const option = {
        tooltip: {
          // trigger: 'axis',
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: [
          {
            type: "category",
            data: this.XData,
            axisTick: {
              alignWithLabel: false,
            },
          },
        ],
        yAxis: {
          axisLine: {
            // 坐标轴 轴线
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["rgba(128, 128, 128, 0.1)"],
            },
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "25%",
            data: this.valueData,
            itemStyle: {
              normal: {
                color: ["#0775F6"],
                label: {
                  show: true,
                  position: "top",
                  textstyle: {
                    color: "#000",
                    fontSize: 16,
                  },
                },
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart1.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;
  .homeTop {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    background: #fff;
    padding: 40px;
    .slelcTop {
      display: flex;
      align-items: center;
      .title {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #171717;
        line-height: 40px;
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
    .numberBottom {
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-top: 34px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: black;
      }
      &::-webkit-scrollbar-track {
        border-radius: 3px;
      }
      .box {
        min-width: 444px;
        height: 142px;
        margin-right: 55px;
        padding: 21px;
        .contentTop {
          width: 100%;
          height: 33px;
          border-left: 2px solid #fff;
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .contentBottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 7px;
          ::v-deep .ant-statistic {
            width: 30%;
            text-align: center;
          }
          ::v-deep .ant-statistic-title {
            color: #fff;
          }
          ::v-deep .ant-statistic-content {
            color: #fff;
          }
        }
      }
    }
  }
  .chartsbom {
    width: 100%;
    height: 500px;
    background: #fff;
    border-radius: 10px;
    margin-top: 40px;
    .slelcTop {
      padding: 20px;
      display: flex;
      align-items: center;
      .title {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #171717;
        line-height: 40px;
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
    #mation_top_fifth_left_echarts {
      margin-top: 24px;
      width: 90%;
      height: 400px;
      canvas {
        width: 90% !important;
        height: 400px;
      }
    }
  }
}
</style>
