<template>
  <div class="secondhandCar">
    <div class="serachtop">
      <a-form layout="inline" ref="FOMRDATA" :model="formData">
        <a-form-item prop="carBrand">
          <a-input
            v-model="formData.carBrand"
            placeholder="请输入汽车品牌"
            :maxLength="10"
            allow-clear
          />
        </a-form-item>
        <a-form-item prop="carName">
          <a-input
            v-model="formData.carName"
            placeholder="请输入汽车名称"
            :maxLength="10"
            allow-clear
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="onSubmit">查询</a-button>
          <a-button style="margin-left: 8px" @click="resetForm">重置</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="btnFuncs">
      <a-button type="primary" size="small" @click="addLine">新增</a-button>
    </div>
    <a-table 
      :columns="columns" 
      :data-source="tableData" 
      :rowKey="(record, index) => { return index }"
      :pagination="{ total:total, pageNum: formData.pageNum, pageSize: formData.pageSize  }"  
      @change="pagetionChange" 
      bordered>
      <template slot="operation" slot-scope="record">
        <a-button type="primary" size="small" @click="editor(record)">编辑</a-button>
        <a-divider type="vertical" />
        <a-button type="primary" size="small" @click="delLine(record)">删除</a-button>
      </template>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel">
      <a-form-model ref="rulemodel" :model="modelForm" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-form-model-item label="汽车品牌" prop="carBrand">
          <a-input v-model="modelForm.carBrand" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="车源地" prop="carCity">
          <a-input v-model="modelForm.carCity" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="首付" prop="carDownPrice">
          <a-input v-model="modelForm.carDownPrice" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="排放标准" prop="carEmission">
          <a-input v-model="modelForm.carEmission" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="发动机" prop="carEngine">
          <a-input v-model="modelForm.carEngine" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="变速箱" prop="carGearbox">
          <a-input v-model="modelForm.carGearbox" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="图片">
          <a-upload
            :customRequest="uploadFile"
            list-type="picture-card"
            :file-list="modelForm.carImage"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="modelForm.carImage.length < 8">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                图片上传
              </div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelFile">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-form-model-item>
        <a-form-model-item label="里程数" prop="carMileage">
          <a-input v-model="modelForm.carMileage" onkeyup="this.value = this.value.replace(/\D/g,'')" :maxLength="10" allow-clear suffix="KM" />
        </a-form-model-item>
        <a-form-model-item label="汽车名称" prop="carName">
          <a-input v-model="modelForm.carName" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="总价" prop="carPrice">
          <a-input v-model="modelForm.carPrice" onkeyup="this.value = this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="上牌时间" prop="carRegisterAt">
          <a-input v-model="modelForm.carRegisterAt" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="百公里加速(s)" prop="carSpeedUp">
          <a-input v-model="modelForm.carSpeedUp" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="汽车视频" prop="carVideo">
          <a-upload
            :customRequest="uploadvideo"
            :multiple="false"
            :file-list="modelForm.carVideo"
            @change="changevideo"
          >
            <a-button> <a-icon type="upload" /> 视频上传 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="轴距" prop="carWheelbase">
          <a-input v-model="modelForm.carWheelbase" :maxLength="10" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="卖家手机号码" prop="sellerPhone">
          <a-input v-model="modelForm.sellerPhone" :maxLength="11" allow-clear />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { getCarList, addCarDetail, carDetail, carDelLine, updateCarDetail } from '@/api/secondhandCar'
import { upLoad } from '@/api/home';
export default {
  data() {
    return {
      loading: false,
      visible: false,
      previewVisible: false,
      title: '新增',
      previewImage: '',
      formData: {
        carBrand: "",
        carName: "",
        pageNum: 1,
        pageSize: 15,
      },
      modelForm: {
        carImage: []
      },
      rules:{
        sellerPhone: [{ required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'change'}]
      },
      total:0,
      columns: [
        {
          title: "汽车品牌",
          dataIndex: "carBrand",
          align: 'center'
        },
        {
          title: "首付",
          dataIndex: "carDownPrice",
          align: 'center'
        },
        {
          title: "汽车名称",
          dataIndex: "carName",
          align: 'center'
        },
        {
          title: "总价",
          dataIndex: "carPrice",
          align: 'center'
        },
        {
          title: "卖家手机号",
          dataIndex: "sellerPhone",
          align: 'center'
        },
        {
          title: "操作",
          dataInde: 'operation',
          width: 200,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      isAdd: true,
    };
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      getCarList({...this.formData}).then(res => {
        if (res.success) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error('This is an error message')
        }
      })
    },
    /* 查询 */
    onSubmit() {
      this.formData.pageNum = 1
      this.formData.pageSize = 15
      this.initData()
    },
    /* 重置 */
    resetForm() {
      this.formData = {
        carBrand: "",
        carName: "",
        pageNum: 1,
        pageSize: 15,
      }
      this.initData()
    },
    pagetionChange(e) {
      this.formData.pageNum = e
      this.initData()
    },
    onShowSizeChange(e) {
      this.formData.pageNum = 1
      this.formData.pageSize = e
      this.initData()
    },  
    editor(e) {
      carDetail({ id: e.id }).then(res => {
        if (!res.data.carVideo) {
          res.data.carVideo = []
        }
        if (!res.data.carImage || res.data.carImage.length === 0) {
          res.data.carImage = []
        }
        this.modelForm = res.data
        this.visible = true
        this.title = '编辑'
      })
    },
    delLine(e) {
      carDelLine([e.id]).then(res => {
        if (res.success) {
          this.$message.success('删除成功')
          this.initData()
        } else {
          this.$message.error('删除失败')
        }
      })
    }, 
    addLine(e) {
      this.modelForm = {
        carImage: []
      }
      this.visible = true
      this.title = '新增'
    },
    uploadFile(e) {
      const formData = new FormData()
      formData.append('file', e.file)
      upLoad(formData).then(res => {
        this.modelForm.carImage.push({
          uid: e.file.uid,
          name: e.file.name,
          url: res.data
        })
      })
    },
    // 视频上传
    uploadvideo(e) {
      const formData = new FormData()
      formData.append('file', e.file)
      upLoad(formData).then(res => {
        this.modelForm.carVideo.push({
          uid: e.file.uid,
          name: e.file.name,
          url: res.data
        })
        e.onSuccess(res, e.file)
      })
    },
    // 视频相关
    changevideo(info) {
      let fileList = [...info.fileList] || [];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.modelForm.carVideo = fileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      (this.modelForm.carImage).forEach((item, index) => {
        if (this.modelForm.carImage[index].status === "removed") {
          this.modelForm.carImage.splice(index, 1)
        }
      })
    },
    handleCancelFile() {
      this.previewVisible = false;
    },
    handleOk(e) {
      const data = {...this.modelForm}
      this.$refs.rulemodel.validate(valid => {
        if (valid) {
          if (this.title === '新增') {
            addCarDetail(data).then(res => {
              if (res.success) {
                this.$message.success(`${this.title}成功!`)
                this.initData()
                this.visible = false
              } else {
                this.$message.error('新增失败,请稍后再试!')
              }
            })
          } else {
            updateCarDetail(data).then(res => {
              if (res.success) {
                this.$message.success(`编辑成功!`)
                this.initData()
                this.visible = false
              } else {
                this.$message.error('编辑失败,请稍后再试!')
              }
            })
          }
          
        }
      })
    },
    handleCancel(e) {
      this.visible = false
    },
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!reg.test(value)) {
        callbackFn('请输入正确的手机号码')
        return
      }
      callbackFn()
    },
  },
};
</script>

<style scoped lang="scss">
.secondhandCar {
  .btnFuncs{
    display: flex;
    padding: 8px 0;
  }
}
::v-deep .ant-modal-body{
    max-height: 500px;
    overflow-y: scroll;
  }
</style>
