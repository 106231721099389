<template>
  <div class="applymengt">
    <div class="serachtop">
      <a-input-search
        v-model="keywords"
        placeholder="请输入学员ID、姓名、手机号"
        style="width: 280px; margin-right: 20px"
        @search="onSearch"
      />
      <div class="rightBtn">
        <a-button type="primary" icon="download" @click="downloadFile(2)"
          >未导出数据</a-button
        >
        <a-button type="primary" icon="download" @click="downloadFile(1)"
          >全部数据</a-button
        >
      </div>
    </div>
    <a-table
      :data-source="dataSource"
      :columns="columns"
      :pagination="{ total:total, pageNum: pageNum, pageSize: pageSize  }"
      @change="pagintion"
    ></a-table>
  </div>
</template>

<script>
import { applyList, exportUserlist } from "@/api/applymengt";
import { download } from "@/utils/download";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      keywords: "",
      total: 0,
      pageNum: 1,
      pageSize: 10,
      dataSource: [],
      columns: [
        {
          title: "用户ID",
          align: "center",
          dataIndex: "id",
        },
        {
          title: "用户昵称",
          align: "center",
          dataIndex: "nickname",
        },
        {
          title: "用户类型",
          align: "center",
          dataIndex: "identity",
        },
        {
          title: "用户姓名",
          align: "center",
          dataIndex: "name",
        },
        {
          title: "用户手机号",
          align: "center",
          dataIndex: "phone",
        },
        {
          title: "报名时间",
          align: "center",
          dataIndex: "signTime",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    initData() {
      const data = {
        name: this.keywords,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      applyList(data).then((res) => {
        this.total = res.data.total;
        this.dataSource = res.data.list
        this.dataSource.forEach((item, index) => {
            item.key = item.signId;
          });
      });
    },
    onSearch() {
      this.pageNum = 1;
      this.initData();
    },
    downloadFile(e) {
      exportUserlist({ type: e }).then((res) => {
        download(res);
      });
    },
    pagintion(e) {
      this.pageNum = e.current
      this.initData()
    }
  },
};
</script>

<style scoped lang="scss">
.applymengt {
  width: 100%;
  height: 100%;
  .serachtop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ::v-deep .ant-btn-primary {
      margin: 0 10px;
    }
  }
  ::v-deep.ant-table-wrapper {
    margin-top: 12px;
  }
  ::v-deep .ant-table-body {
    background: #fff;
    min-height: 750px;
    overflow-y: scroll;
  }
  ::v-deep .ant-table-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 55px;
  }
}
</style>
