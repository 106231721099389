<template>
  <div class="questionbank">
    <div class="questionTop fl-between">
      <div class="leftselect fl-between">
        <div>类型：
          <a-select :default-value="active" style="width: 120px" @change="handleChange">
            <a-select-option :value="0">
              全部
            </a-select-option>
            <a-select-option :value="1">
              判断
            </a-select-option>
            <a-select-option :value="2">
              单选
            </a-select-option>
            <a-select-option :value="3">
              多选
            </a-select-option>
          </a-select>
        </div>
        <div>状态：
          <a-select :default-value="active1" style="width: 120px" @change="handleChange1">
            <a-select-option :value="1">
              全部
            </a-select-option>
            <a-select-option :value="2">
              启用
            </a-select-option>
            <a-select-option :value="3">
              禁用
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div>
        <a-button type="danger" @click="delmore">批量删除</a-button>
        <a-button type="primary" @click="gonext">题目发布</a-button>
      </div>
    </div>
    <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :data-source="dataSource" :columns="columns" :pagination="{ total:total, pageNum: pageNum, pageSize: pageSize  }" @change="pagetion">
      <template slot="disabled" slot-scope="text,record">
        <a-switch slot="disabled" un-checked-children="关" checked-children="开" v-model="record.disabled" @change="onChange(record)" />
      </template>
      <template slot="operations" slot-scope="record">
        <a-button slot="ID" type="primary" @click="compile(record)">编辑</a-button>
        <a-divider type="vertical" />
        <a-button type="danger" @click="remove(record)">删除</a-button>
      </template>
    </a-table>
  </div>
</template>

<script>
import { topicLists, dsiablepic, enalb, delTopic, delmorePic } from '@/api/questionbank';
export default {
  name:'',
  components: {

  },
  props: {

  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0,
      selectedRowKeys: [],
      active: 0,
      active1: 1,
      dataSource: [],
      columns: [{
        title: '考题ID',
        align: 'center',
        dataIndex: 'topicId',
        
      }, {
        title: '科目',
        align: 'center',
        key: 'subject',
        dataIndex: 'subject',
        
      }, {
        title: '类型',
        align: 'center',
        key: 'type',
        dataIndex: 'type',
        
      }, {
        title: '发布时间',
        align: 'center',
        key: 'time',
        dataIndex: 'createdAt',
      }, {
        title: '状态',
        align: 'center',
        dataIndex: 'disabled',
        scopedSlots: { customRender: 'disabled' }
      }, {
        title: '操作',
        align: 'center',
        key: 'operations',
        scopedSlots: { customRender : 'operations' }
      }]
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.initData()
  },
  mounted() {

  },
  methods: {
    pagetion(e) {
      this.pageNum = e.current
      this.initData()
    },
    // 初始数据
    initData() {
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        status: this.active1,
        type: this.active
      }
      topicLists(data).then(res => {
        this.dataSource = res.data.list
        this.dataSource.forEach((item, index) => {
          item.key = item.topicId
          item.disabled = !item.disabled
        });
        this.total = res.data.total
      })
    },
    // 批量删除
    delmore() {
      if(this.selectedRowKeys.length === 0) {
        this.$message.error('请选择要删除的项！')
        return false
      } else {
        delmorePic(this.selectedRowKeys).then(res => {
          if (res.httpStatus === 200) {
            this.initData()
            this.$message.success('删除成功')
          } else {
            this.$message.error(`${res.message}`)
          }
        })
      } 
    },
    // 复选框状态值变化事件
    onSelectChange(e) {
      console.log(e);
      this.selectedRowKeys = e
    },
    handleChange(e) {
      this.active = e
      this.initData()
    },
    handleChange1(e) {
      this.active1 = e
      this.initData()
    },
    // 启用或者禁用题目
    onChange(e) {
      console.log(e);
      if (e.disabled === false) {
        dsiablepic(e.topicId).then(res => {
          if (res.httpStatus === 200) {
            this.$message.success('状态修改成功')
            this.initData()
          } else {
            this.$message.error(`${res.message}`)
          }
        })
      } else {
        enalb(e.topicId).then(res => {
          if (res.httpStatus === 200) {
            this.$message.success('状态修改成功')
            this.initData()
          } else {
            this.$message.error(`${res.message}`)
          }
        })
      }
    },
    /**编辑 */
    compile(e) {
      this.$router.push({
        path: '/issue',
        query: {
          id: e.topicId
        }
      })
    },
    // 删除
    remove(e) {
      const that = this
      this.$confirm({
        title: '确定要删除吗?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          delTopic(e.topicId).then(res => {
            if (res.httpStatus === 200) {
              that.$message.success('删除成功')
              that.initData()
            } else {
              that.$message.error(`${res.message}`)
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        },
      })
    },
    gonext() {
      this.$router.push('/issue')
    }
  }
}
</script>

<style scoped lang="scss">
.questionbank{
  width: 100%;
  height: 100%;
  .questionTop{
    .leftselect{
      width: 400px;
    }
    ::v-deep .ant-btn{
      margin: 0 10px;
    }
  }
  ::v-deep.ant-table-wrapper{
    margin-top: 12px;
  }
  ::v-deep .ant-table-body{
    background: #fff;
    min-height: 750px;
  }
  ::v-deep .ant-table-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 55px;
  }
}
</style>
