<template>
  <a-modal
    :title="item.id || item.userId ? '详情' : '教练入驻'"
    :visible="isShow"
    @ok="handleOk"
    @cancel="handleCancel"
  >
  <a-form-model
    v-if="cars === 1"
    ref="ruleForm"
    :model="item"
    :rules="rules"
    :label-col="labelCol" 
    :wrapper-col="wrapperCol">
    <a-form-model-item label="用户ID">
      {{item.id}}
    </a-form-model-item>
    <a-form-model-item label="用户昵称">
      {{item.name}}
    </a-form-model-item>
    <a-form-model-item label="教练姓名" prop="coachName">
      <a-input v-model="item.coachName" placeholder="请输入教练姓名" />
    </a-form-model-item>
    <a-form-model-item label="教练手机号" prop="coachPhone">
      <a-input v-model="item.coachPhone" placeholder="请输入教练手机号" />
    </a-form-model-item>
    <a-form-model-item label="驾校名称" prop="driverName">
      <a-input v-model="item.driverName" placeholder="请输入驾校名称" />
    </a-form-model-item>
  </a-form-model>
  <main v-if="cars === 2">
    <a-row>
      <a-col :span="3" style="">用户ID:</a-col>
      <a-col :span="6">{{item.id ? item.id : item.userId}}</a-col>
      <a-col :span="4">用户昵称:</a-col>
      <a-col :span="6">{{item.name}}</a-col>
    </a-row>
    <a-row>
      <a-col :span="3">姓名:</a-col>
      <a-col :span="6">{{item.coachName}}</a-col>
      <a-col :span="3">手机号:</a-col>
      <a-col :span="6">{{item.coachPhone}}</a-col>
    </a-row>
    <a-row>
      <a-col :span="4">用户类型:</a-col>
      <a-col :span="12">{{item.identity}}</a-col>
    </a-row>
    <a-row>
      <a-col :span="4">驾校名称:</a-col>
      <a-col :span="12">{{item.driverName}}</a-col>
    </a-row>
  </main>
  </a-modal>
</template>

<script>
import { enterCoach } from '@/api/usercontrol';
export default {
  name: "",
  components: {},
  props: {
    isShow: {
      type: Boolean
    },
    item: {
      type: Object
    },
    cars: {
      type: Number
    }
  },
  data() {
    let myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
    let phoneTest = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!myreg.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      rules: {
        coachName: [{ required: true, message: '请输入教练姓名', trigger: 'blur' }],
        coachPhone: [{ required: true, validator: phoneTest, trigger: 'blur' }],
        driverName: [{ required: true, message: '请输入驾校名称', trigger: 'blur' }]
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    console.log(this.item)
  },
  mounted() {},
  methods: {
    handleOk() {
      if (this.cars === 2) {
        this.$emit('update:isShow', false)
      } else {
        this.$refs.ruleForm.validate(valid => {
        if (valid) {
          enterCoach({...this.item, userId: this.item.id, phone: this.item.coachPhone, name: this.item.coachName}).then(res => {
            if (res.httpStatus === 200) {
              this.$emit('update:isShow', false)
              this.$parent.initData()
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
      }
    },
    handleCancel() {
      this.$emit('update:isShow', false)
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .ant-form-item{
  display: flex;
  align-items: center;
}
</style>
