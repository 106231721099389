import axios from 'axios';

// 获取报名列表
export const applyList = apiParams => {
  return axios({
    method: 'get',
    url: 'admin/signList',
    params: apiParams
  })
}

// 导出用户报名列表
export const exportUserlist = apiParams => {
  return axios({
    method: 'get',
    url: 'admin/export',
    params: apiParams,
    responseType: 'blob'
  })
}

/* 二手车轮播图保存 */
export const addBanner = apiParams => {
  return axios({
    method: 'post',
    url: '/admin/add/car/banner',
    data: apiParams
  })
}


/* 二手车查询 */
export const bannerList = apiParams => {
  return axios({
    method: 'get',
    url: '/admin/query/car/banner',
    params: apiParams
  })
}

/* 修改轮播图 */
export const editorBanner = apiParams => {
  return axios({
    method: 'post',
    url: '/admin/update/car/banner',
    data: apiParams
  })
}

/* 删除轮播图 */
export const delBanner = apiParams => {
  return axios({
    method: 'post',
    url: '/admin/delete/car/banner',
    data: apiParams
  })
}
