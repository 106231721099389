import axios from 'axios';

// 批量删除支付方案
export const delmoreScheme = apiParams => {
  return axios({
    method: 'delete',
    url: 'admin/batchDeleteProject',
    data: apiParams
  })
}

// 删除单个支付方案
export const delScheme = apiParams => {
  return axios({
    method: 'DELETE',
    url: `admin/deleteProject?projectId=${apiParams}`
  })
}

// 分页查询交易记录
export const pagePayagent = apiParams => {
  return axios({
    method: 'get',
    url: 'admin/getOrderNoticeList',
    params: apiParams
  })
}

// 分页查询支付方案
export const payProjectList = apiParams => {
  return axios({
    method: 'get',
    url: 'admin/getProject',
    params: apiParams
  })
}

// 新增支付方案
export const addPayproject = apiParams => {
  return axios({
    method: 'post',
    url: 'admin/saveProject',
    data: apiParams
  })
}

// 禁用支付方案
export const disabledPryproject = apiParams => {
  return axios({
    method: 'PATCH',
    url: `admin/disableProject?projectId=${apiParams}`
  })
}

// 启用支付方案
export const truePayproject = apiParams => {
  return axios({
    method: 'PATCH',
    url: `admin/enableProject?projectId=${apiParams}`
  })
}

// 编辑价格的接口
export const payEditor = apiParams => {
  return axios({
    method: 'post',
    url: '/admin/updateProject',
    data: apiParams
  })
}